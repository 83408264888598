import React from "react";
import "./WorkProcess.css";
import WorkProcessVid from "../../assets/videos/video.mp4";
import {ControlBar, Player, PlayToggle} from 'video-react';
import 'video-react/dist/video-react.css';

const WorkProcess = () => {
    return (
        <div id="process" className="work-section workprocess section__padding">
            <div className="container content">
                <div className="workprocess__content text__center">
                    <h2 className="section__title text__cap">Our work process</h2>
                    <p>Our work process in web development is a seamless blend of creativity and technology. We start by understanding your unique requirements and then move on to a brainstorming session where ideas flow freely. Our development team meticulously crafts each line of code to ensure a responsive, user-friendly interface. Finally, we rigorously test the product to guarantee a flawless experience across all platforms.
</p>

                    <div className="vid__container">
                        <Player autoPlay loop src = {WorkProcessVid}>
                            <ControlBar autoHide = {false} disableDefaultControls = {true}>
                                <PlayToggle />
                            </ControlBar>
                        </Player>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default WorkProcess;