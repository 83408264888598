import React from "react";
import { FaCode, FaMobileAlt, FaPaintBrush} from "react-icons/fa";
import { BiCustomize } from "react-icons/bi";
import { GiIdCard } from "react-icons/gi";
import { RiMap2Line } from "react-icons/ri";
import { FaNewspaper } from "react-icons/fa";

import "./Services.css";

const Services = () => {
    return (
        <section className="services-section" id="services">
        <div className="container content">
          <h2>Our Services</h2>
          <div className="services">
            <div className="service">
              <FaCode className="icon" />
              <h3>Web Development</h3>
              <p>
                Custom websites built with the latest technologies for optimal
                performance and user experience.
              </p>
            </div>
            <div className="service">
              <FaMobileAlt className="icon" />
              <h3>Responsive Design</h3>
              <p>
                Designs that adapt seamlessly to any device, ensuring your
                website looks great on all screens.
              </p>
            </div>
            <div className="service">
              <FaPaintBrush className="icon" />
              <h3>UI/UX Design</h3>
              <p>
                User-centric designs that are both visually stunning and
                intuitive, enhancing user engagement.
              </p>
            </div>
            <div className="service">
              <GiIdCard className="icon" />
              <h3>Cards/Bussiness Cards</h3>
              <p>
              We provide customizable business cards that reflect your brand’s identity and make a memorable statement.
              </p>
            </div>
            <div className="service">
              <RiMap2Line className="icon" />
              <h3>Brouchers</h3>
              <p>
              Our expertly designed brochures combine stunning visuals with clear messaging to showcase your brand effectively.
              </p>
            </div>
            <div className="service">
              <FaNewspaper className="icon" />
              <h3>Flyers</h3>
              <p>
              We create striking flyers with vibrant designs to capture attention and effectively communicate your message.
              </p>
            </div>
            <div className="service">
              <BiCustomize className="icon" />
              <h3>Customize Mugs, Hoodies, Shirts and Calenders</h3>
              <p>
              Our custom mugs, hoodies, shirts, and calendars let you showcase your unique style or brand, with personalized designs that stand out.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Services;