import React from 'react';
import './MailUs.css';

function MailUs() {
  return (
    <div>
         <section className="contact-section">
        <div className="container">
          <h2>Get In Touch</h2>
          <p>
            Ready to start your project with us? Reach out today, and let's
            build something amazing together.
          </p>
          <br></br>
          <a href="mailto:info@webblaze.online" className="contact-button">
            Contact Us
          </a>
        </div>
      </section> 
    </div>
  )
}

export default MailUs
