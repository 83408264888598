import React from "react";
import "./Header.css";
import Navbar from "../Navbar/Navbar";
import images from "../../constants/images";

const Header = () => (
    <div className="header" style = {{
        background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)), url(${images.header_bg}) center/cover no-repeat`
    }}>
        <Navbar />

        <div className="container">
            <div className="header__content text__center text__light flex flex__center">
                <div className="header__content--center">
                    <h1 className="header__title fw__6">We Design and Develop</h1>
                   <i><p className="para__text">At<span style={{color:'#0fb9b4', fontFamily:'ariel', fontSize:'1.2em', fontWeight:'bold', marginLeft:'2px'}}>Web Blaze</span>, we blend creativity with technology to craft stunning and functional websites that captivate audiences. Our expert team specializes in web design and development, ensuring each project is tailored to your brand's unique identity and goals. From intuitive user interfaces to seamless functionality, we turn your vision into a dynamic online presence.</p></i>
                    <a href = "#contact" ><button class='glowing-btn'><span class='glowing-txt'>CO<span class='faulty-letter'>N</span>TACT</span>&nbsp;&nbsp;&nbsp;U<span class='faulty-letter'>S</span></button></a>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
            </div>
        </div>
    </div>
)

export default Header;