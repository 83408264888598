import React from "react";
import "./AboutUs.css";
import team from "../../assets/images/aboutUs.jpg";

const AboutUs = () => {
  return (
    <div className="about-us">
      <section className="about-section" id="about">
        <div className="container">
          <div className="content">
            <h2>About Us</h2>
            <p>
              We are a team of passionate designers and developers committed to
              building exceptional websites. With a keen eye for design and a
              knack for innovation, we bring your ideas to life with pixel-perfect
              precision.
            </p>
            <a href="#learn-more" className="learn-more-button">
              Learn More
            </a>
          </div>
         
          <div className="image waving-image">
            <img
              src={team}
              alt="Team Working"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
