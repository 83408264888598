import header_bg from "../assets/images/header-bg.jpg";
import logo from "../assets/images/logo.jpeg";
import signature from "../assets/images/signature.png";
import icon_archer from "../assets/images/icon-archer.png";
import icon_briefcase from "../assets/images/icon-briefcase.png";
import icon_clock from "../assets/images/icon-clock.png";
import icon_console from "../assets/images/icon-console.png";
import icon_diamond from "../assets/images/icon-diamond.png";
import icon_eye from "../assets/images/icon-eye.png";
import icon_fantasy from "../assets/images/icon-fantasy.png";
import icon_heart from "../assets/images/icon-heart.png";
import icon_paint from "../assets/images/icon-paint.png";
import icon_phone from "../assets/images/icon-phone.png";
import icon_plane from "../assets/images/icon-plane.png";
import icon_star from "../assets/images/icon-star.png";
import icon_star_white from "../assets/images/icon-star-white.png";
import skills_bg from "../assets/images/skills-bg.jpg";
import work_1 from "../assets/images/work-1.jpg";
import work_2 from "../assets/images/work-2.jpg";
import work_3 from "../assets/images/work-3.jpg";
import work_4 from "../assets/images/work-4.jpg";
import work_5 from "../assets/images/work-5.jpg";
import work_6 from "../assets/images/work-6.jpg";
import work_7 from "../assets/images/work-7.jpg";
import work_8 from "../assets/images/work-8.jpg";

export default {
  header_bg,
  logo,
  signature,
  icon_archer,
  icon_briefcase,
  icon_clock,
  icon_console,
  icon_diamond,
  icon_eye,
  icon_fantasy,
  icon_heart,
  icon_paint,
  icon_phone,
  icon_plane,
  icon_star,
  icon_star_white,
  skills_bg,
  work_1,
  work_2,
  work_3,
  work_4,
  work_5,
  work_6,
  work_7,
  work_8,
};
