import React, { useState } from "react";
import { BsEmojiLaughingFill } from "react-icons/bs";
import "./Contact.css";



const Contact = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        title: "",
        comment: "",
      });
    
      const [isSubmitting, setIsSubmitting] = useState(false);
      const [submitted, setSubmitted] = useState(false);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
    
        // Simulate an API call
        setTimeout(() => {
          setSubmitted(true);
          setIsSubmitting(false);
          // Reset form
          setFormData({ name: "", email: "", title: "", comment: "" });
        }, 2000);
    };
    return (
        <div id="contact" className="contacts contacts-section section__padding bg__whitesmoke">
            <div className="contact-container container content">
                <h2 className="section__title text__center">Need a Project?</h2>
                <p className="para__text text__center">  We are eager to hear from you! Please fill out the form below with
                your inquiries or feedback, and we'll get back to you promptly.</p>
                <div className="contact__content">
                <form onSubmit={handleSubmit}>
                        <div className="form-group form__elem">
                            <input type = "text" className="form__control" name="name" value={formData.name} onChange={handleChange} placeholder="Your Name" required />
                            <div className="underline"></div>
                        </div>
                        <div className="form-group form__elem">
                        <input type = "text" className="form__control" name="email" value={formData.email} onChange={handleChange} placeholder="Your Email" required />
                        <div className="underline"></div>
                        </div>
                        <div className="form-group form__elem">
                            <input type = "text" className="form__control" name="title" value={formData.title} onChange={handleChange} placeholder="Title" required />
                            <div className="underline"></div>
                        </div>
                        <div className="form-group form__elem">
                            <textarea className="form-group form__control" name="comment" value={formData.comment} onChange={handleChange} placeholder="Your Comment" required rows = "6"></textarea>
                            <div className="underline"></div>
                        </div>
                        <br></br>
                        <button
            type="submit"
            className={`submit-button ${isSubmitting ? "loading" : ""}`}
          >
            <span className="submit-design">{isSubmitting ? "Submitting..." : "Submit"}</span>
            <svg viewBox="-5 -5 110 110" preserveAspectRatio="none" aria-hidden="true">
              <path d="M0,0 C0,0 100,0 100,0 C100,0 100,100 100,100 C100,100 0,100 0,100 C0,100 0,0 0,0" />
            </svg>
          </button>
                    </form>
                    {submitted && (
                  <p className="thank-you-message">Thank you for your message! &nbsp;<BsEmojiLaughingFill style={{fontSize:'1.5rem'}}/></p>
                   )}
                </div>
            </div>
        </div>
    )
}

export default Contact;