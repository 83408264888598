import React from "react";
import "./Footer.css";
import { AiFillEnvironment } from "react-icons/ai";
import { AiFillMail } from "react-icons/ai";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { AiFillGoogleCircle } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import payoneer from "../../assets/images/payoneer.jpg";
import paypal from "../../assets/images/paypal.jpg";
import jazzcash from "../../assets/images/jazzcash.jpg";
import easypaisa from "../../assets/images/easypaisa.jpg";
import sadapay from "../../assets/images/sadapay.jpg";

const Footer = () => {
  return (
    <footer className="footer section__padding bg__dark">
      <div className="container">
        <div className="footer__content grid text__light text__center">
          <div className="footer__content--item contact-info">
              <h1  className="hide">Contact Us</h1>
              <br />
              <p style={{ textTransform: "initial" }}>
                <AiFillEnvironment style={{ color: "red" }} />
                &nbsp;Karachi, Pakistan
              </p>
              <p style={{ textTransform: "lowercase" }}>
                <AiFillMail style={{ color: "#FF512F" }} />
                &nbsp;Info@webblaze.online
              </p>
              <p>
                <AiOutlineWhatsApp style={{ color: "green" }} />
                &nbsp;+92-309-2501268
              </p>

          </div>

          <div className="footer__content--item payment-methods">
            <div>
              <h1 className="hide">Payment in Other Countries</h1>
              <br></br>
              <div className="payment-icons">
                <img className="payment-icon" src={paypal} alt="Paypal" />
                <img className="payment-icon" src={payoneer} alt="Payoneer" />
              </div>
            </div>
          </div>

          <div  className="footer__content--item payment-methods">
            <div>
              <h1 className="hide">Payment Partners in Pakistan</h1>
              <br></br>
              <div className="payment-icons">
                <img className="payment-icon" src={jazzcash} alt="Jazzcash" />
                <img className="payment-icon" src={easypaisa} alt="Easypaisa" />
                <img className="payment-icon" src={sadapay} alt="Sadapay" />
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <ul className="socials">
          <li>
            <a
              href="https://www.digistitch.store/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillGoogleCircle />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=61561506538320&mibextid=ZbWKwL/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillFacebook />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/profile.php?id=61561506538320&mibextid=ZbWKwL/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillInstagram />
            </a>
          </li>
        </ul>
        <br></br>
        <div style={{ color: "#0fb9b4", textAlign: "center" }}>
          Copyright &copy; 2024 Web Blaze
        </div>
      </div>
    </footer>
  );
};

export default Footer;
