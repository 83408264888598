import React from "react";
import "./Navbar.css";
import { FaTimes } from "react-icons/fa";

const ImagePreviewModal = ({ imageSrc, onClose }) => {
  return (
    <div className="modal__overlay">
      <div className="modal__content">
        <button className="modal__close" onClick={onClose}>
          <FaTimes size={24} />
        </button>
        <img src={imageSrc} alt="Full-size preview" className="modal__image" />
      </div>
    </div>
  );
};

export default ImagePreviewModal;
