// src/components/Navbar.js

import React, { useState } from "react";
import images from "../../constants/images";
import "./Navbar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import ImagePreviewModal from "../../components/Navbar/ImagePreviewModal.js"; // Import the modal component

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);

  // Function to handle opening the image preview modal
  const handleLogoClick = () => {
    setShowImagePreview(true);
  };

  return (
    <>
      <nav className="navbar">
        <div className="container flex">
          <div className="brand__and__toggler flex">
              <img className="logo" src={images.logo} alt="site logo"  onClick={handleLogoClick} ></img>
            <button
              type="button"
              className="navbar__open--btn text__light"
              onClick={() => setToggleMenu(true)}
            >
              <FaBars size={26} />
            </button>
          </div>

          <div className="navbar__collapse">
            <ul className="navbar__nav">
              <li className="nav__item">
                <a
                  href="#home"
                  className="nav__link text__upper fw__6 text__light nav__active"
                >
                  home
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#about"
                  className="nav__link text__upper fw_6 text__light"
                >
                  about
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#process"
                  className="nav__link text__upper fw_6 text__light"
                >
                  process
                </a>
                </li>
                <li className="nav__item">
                <a
                  href="#skill"
                  className="nav__link text__upper fw_6 text__light"
                >
                  skills
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#services"
                  className="nav__link text__upper fw_6 text__light"
                >
                  services
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#testimonials"
                  className="nav__link text__upper fw_6 text__light"
                >
                  testimonials
                </a>
              </li>
              <li className="nav__item">
                <a
                  href="#contact"
                  className="nav__link text__upper fw_6 text__light"
                >
                  contact
                </a>
              </li>
            </ul>
          </div>

          {toggleMenu && (
            <div className="navbar__smallscreen">
              <button
                type="button"
                className="navbar__close--btn text__light"
                onClick={() => setToggleMenu(false)}
              >
                <FaTimes size={32} />
              </button>
              <ul className="navbar__nav--smallscreen text__light">
                <li className="nav__item">
                  <a
                    href="#home"
                    className="nav__link text__upper fw__6 nav__active text__light"
                  >
                    home
                  </a>
                </li>
                <li className="nav__item">
                  <a
                    href="#about"
                    className="nav__link text__upper fw__6 text__light"
                  >
                    about
                  </a>
                </li>
                <li className="nav__item">
                  <a
                    href="#process"
                    className="nav__link text__upper fw__6 text__light"
                  >
                    process
                  </a>
                </li>
                <li className="nav__item">
                  <a
                    href="#skill"
                    className="nav__link text__upper fw__6 text__light"
                  >
                 skills                 
                 </a>
                </li>
                <li className="nav__item">
                  <a
                    href="#services"
                    className="nav__link text__upper fw__6 text__light"
                  >
                    services
                  </a>
                </li>
                <li className="nav__item">
                  <a
                    href="#testimonials"
                    className="nav__link text__upper fw__6 text__light"
                  >
                    testimonials
                  </a>
                </li>
                <li className="nav__item">
                  <a
                    href="#contact"
                    className="nav__link text__upper fw__6 text__light"
                  >
                    contact
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>

      {showImagePreview && (
        <ImagePreviewModal
          imageSrc={images.logo} // Pass the image source
          onClose={() => setShowImagePreview(false)} // Function to close the modal
        />
      )}
    </>
  );
};

export default Navbar;
